/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyCau07Gc01nUWXLogHuIvYFlKd-nPgXjmg",
  "appId": "1:528263319500:web:2c9e39dc36ad7318d69769",
  "authDomain": "schooldog-i-marietta-ga.firebaseapp.com",
  "measurementId": "G-CGMC9GQ59J",
  "messagingSenderId": "528263319500",
  "project": "schooldog-i-marietta-ga",
  "projectId": "schooldog-i-marietta-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-marietta-ga.appspot.com"
}
